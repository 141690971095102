*, :after, :before {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:after, :before {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  line-height: 1.5;
  font-family: var(--font-sans), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-feature-settings: normal;
  font-variation-settings: normal;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, pre, samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dd, dl, figure, h1, h2, h3, h4, h5, h6, hr, p, pre, fieldset {
  margin: 0;
}

fieldset, legend {
  padding: 0;
}

menu, ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

[role="button"], button {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

audio, canvas, embed, iframe, img, object, svg, video {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :after, :before, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: sticky !important;
}

.inset-0 {
  inset: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-4 {
  bottom: 1rem !important;
}

.bottom-5 {
  bottom: 1.25rem !important;
}

.bottom-6 {
  bottom: 1.5rem !important;
}

.left-0 {
  left: 0 !important;
}

.left-1\/2 {
  left: 50% !important;
}

.left-4 {
  left: 1rem !important;
}

.left-5 {
  left: 1.25rem !important;
}

.left-6 {
  left: 1.5rem !important;
}

.right-0 {
  right: 0 !important;
}

.right-4 {
  right: 1rem !important;
}

.top-0 {
  top: 0 !important;
}

.top-4 {
  top: 1rem !important;
}

.top-6 {
  top: 1.5rem !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-20 {
  z-index: 20 !important;
}

.z-30 {
  z-index: 30 !important;
}

.col-start-1 {
  grid-column-start: 1 !important;
}

.col-end-4 {
  grid-column-end: 4 !important;
}

.mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-12 {
  margin-bottom: 3rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-2\.5 {
  margin-bottom: .625rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-5 {
  margin-bottom: 1.25rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.ml-12 {
  margin-left: 3rem !important;
}

.ml-16 {
  margin-left: 4rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.ml-32 {
  margin-left: 8rem !important;
}

.ml-4 {
  margin-left: 1rem !important;
}

.ml-6 {
  margin-left: 1.5rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mr-10 {
  margin-right: 2.5rem !important;
}

.mr-16 {
  margin-right: 4rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mr-20 {
  margin-right: 5rem !important;
}

.mr-30 {
  margin-right: 7.5rem !important;
}

.mr-4 {
  margin-right: 1rem !important;
}

.mr-6 {
  margin-right: 1.5rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-10 {
  margin-top: 2.5rem !important;
}

.mt-12 {
  margin-top: 3rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-20 {
  margin-top: 5rem !important;
}

.mt-4 {
  margin-top: 1rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.block {
  display: block !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.grid {
  display: grid !important;
}

.h-1 {
  height: .25rem !important;
}

.h-10 {
  height: 2.5rem !important;
}

.h-100 {
  height: 23.2rem !important;
}

.h-12 {
  height: 3rem !important;
}

.h-14 {
  height: 3.5rem !important;
}

.h-2 {
  height: .5rem !important;
}

.h-4 {
  height: 1rem !important;
}

.h-40 {
  height: 10rem !important;
}

.h-50 {
  height: 12.5rem !important;
}

.h-6 {
  height: 1.5rem !important;
}

.h-8 {
  height: 2rem !important;
}

.h-\[371px\] {
  height: 371px !important;
}

.h-full {
  height: 100% !important;
}

.min-h-\[calc\(100\%-200px\)\] {
  min-height: calc(100% - 200px) !important;
}

.min-h-\[calc\(100\%-88px\)\] {
  min-height: calc(100% - 88px) !important;
}

.w-1 {
  width: .25rem !important;
}

.w-1\/2 {
  width: 50% !important;
}

.w-10 {
  width: 2.5rem !important;
}

.w-100 {
  width: 23.2rem !important;
}

.w-109 {
  width: 27.25rem !important;
}

.w-11 {
  width: 2.75rem !important;
}

.w-128 {
  width: 32rem !important;
}

.w-129 {
  width: 32.25rem !important;
}

.w-142 {
  width: 35.5rem !important;
}

.w-2 {
  width: .5rem !important;
}

.w-30 {
  width: 7.5rem !important;
}

.w-4 {
  width: 1rem !important;
}

.w-40 {
  width: 10rem !important;
}

.w-45 {
  width: 11.25rem !important;
}

.w-50 {
  width: 12.5rem !important;
}

.w-6 {
  width: 1.5rem !important;
}

.w-8 {
  width: 2rem !important;
}

.w-\[168px\] {
  width: 168px !important;
}

.w-\[371px\] {
  width: 371px !important;
}

.w-\[513px\] {
  width: 513px !important;
}

.w-\[526px\] {
  width: 526px !important;
}

.w-full {
  width: 100% !important;
}

.w-max {
  width: max-content !important;
}

.max-w-xl {
  max-width: 36rem !important;
}

.flex-1 {
  flex: 1 !important;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50% !important;
}

.-translate-x-1\/2, .rotate-0 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.rotate-0 {
  --tw-rotate: 0deg !important;
}

.rotate-180 {
  --tw-rotate: 180deg !important;
}

.rotate-180, .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-col {
  flex-direction: column !important;
}

.items-start {
  align-items: flex-start !important;
}

.items-center {
  align-items: center !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.gap-2 {
  gap: .5rem !important;
}

.gap-4 {
  gap: 1rem !important;
}

.rounded-2xl {
  border-radius: 1rem !important;
}

.rounded-3xl {
  border-radius: 1.5rem !important;
}

.rounded-4xl {
  border-radius: 1.875rem !important;
}

.rounded-6xl {
  border-radius: 2.815rem !important;
}

.rounded-full {
  border-radius: 9999px !important;
}

.rounded-xl {
  border-radius: .75rem !important;
}

.border {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-\[\#43FF8E\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(67 255 142 / var(--tw-border-opacity)) !important;
}

.border-\[\#F0FF43\] {
  --tw-border-opacity: 1 !important;
  border-color: rgb(240 255 67 / var(--tw-border-opacity)) !important;
}

.border-blue {
  --tw-border-opacity: 1 !important;
  border-color: rgb(66 0 255 / var(--tw-border-opacity)) !important;
}

.border-darkWhite {
  border-color: #fff6 !important;
}

.border-purple {
  border-color: rgb(107 76 234 / var(--tw-border-opacity)) !important;
}

.border-purple, .border-white {
  --tw-border-opacity: 1 !important;
}

.border-white {
  border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.border-yellow {
  --tw-border-opacity: 1 !important;
  border-color: rgb(240 255 67 / var(--tw-border-opacity)) !important;
}

.border-opacity-60 {
  --tw-border-opacity: .6 !important;
}

.bg-\[\#000000b3\] {
  background-color: #000000b3 !important;
}

.bg-\[\#00FFA3\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 255 163 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#01020399\] {
  background-color: #01020399 !important;
}

.bg-\[\#01020999\] {
  background-color: #01020999 !important;
}

.bg-\[\#86920099\] {
  background-color: #86920099 !important;
}

.bg-\[\#A993FF\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(169 147 255 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#F0FF4366\] {
  background-color: #f0ff4366 !important;
}

.bg-\[\#F0FF43\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(240 255 67 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#FFB31F\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 179 31 / var(--tw-bg-opacity)) !important;
}

.bg-darkWhite {
  background-color: #fff6 !important;
}

.bg-lightBlack {
  background-color: #03182a66 !important;
}

.bg-lightBlue {
  background-color: #4200ff99 !important;
}

.bg-metamask {
  background-color: rgb(246 133 27 / var(--tw-text-opacity)) !important;
}

.bg-polkadot {
  background-color: rgb(252 0 123 / var(--tw-text-opacity)) !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.bg-opacity-20 {
  --tw-bg-opacity: .2 !important;
}

.bg-box-bg {
  background-image: url("treasureBoxBg.2a494da7.02d152ac.png") !important;
}

.bg-home-bg {
  background-image: url("homeBg.aa0d0fbb.3be2371d.png") !important;
}

.bg-nft-bg {
  background-image: url("nftBg.70879e7b.d59cc47e.png") !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-center {
  background-position: 50% !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.object-cover {
  -o-object-fit: cover !important;
  object-fit: cover !important;
}

.p-2 {
  padding: .5rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.p-6 {
  padding: 1.5rem !important;
}

.px-1 {
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.px-12 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-16 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.px-36 {
  padding-left: 9rem !important;
  padding-right: 9rem !important;
}

.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important;
}

.py-1\.5 {
  padding-top: .375rem !important;
  padding-bottom: .375rem !important;
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.py-2\.5 {
  padding-top: .625rem !important;
  padding-bottom: .625rem !important;
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.pb-20 {
  padding-bottom: 5rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}

.text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.text-5\.5xl {
  font-size: 3.5rem !important;
}

.text-5xl {
  font-size: 3rem !important;
  line-height: 1 !important;
}

.text-6\.25xl {
  font-size: 4rem !important;
}

.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-sm {
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-semibold {
  font-weight: 600 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.italic {
  font-style: italic !important;
}

.leading-17\.375 {
  line-height: 4.34375rem !important;
}

.leading-\[1\.2\] {
  line-height: 1.2 !important;
}

.leading-\[57\.6px\] {
  line-height: 57.6px !important;
}

.leading-\[69\.5px\] {
  line-height: 69.5px !important;
}

.text-\[\#00FFA3\] {
  --tw-text-opacity: 1 !important;
  color: rgb(0 255 163 / var(--tw-text-opacity)) !important;
}

.text-\[\#A993FF\] {
  --tw-text-opacity: 1 !important;
  color: rgb(169 147 255 / var(--tw-text-opacity)) !important;
}

.text-\[\#F0FF43CC\] {
  color: #f0ff43cc !important;
}

.text-\[\#F0FF43\] {
  --tw-text-opacity: 1 !important;
  color: rgb(240 255 67 / var(--tw-text-opacity)) !important;
}

.text-\[\#FFB31F\] {
  --tw-text-opacity: 1 !important;
  color: rgb(255 179 31 / var(--tw-text-opacity)) !important;
}

.text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.text-lightBlack {
  color: #03182a66 !important;
}

.text-white {
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-white, .text-yellow {
  --tw-text-opacity: 1 !important;
}

.text-yellow {
  color: rgb(240 255 67 / var(--tw-text-opacity)) !important;
}

.text-opacity-100 {
  --tw-text-opacity: 1 !important;
}

.text-opacity-40 {
  --tw-text-opacity: .4 !important;
}

.text-opacity-60 {
  --tw-text-opacity: .6 !important;
}

.text-opacity-80 {
  --tw-text-opacity: .8 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.opacity-40 {
  opacity: .4 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.mix-blend-hard-light {
  mix-blend-mode: hard-light !important;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px) !important;
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.ui, button, div, h1, h2, h3, h4, h5, h6, html, input, p, span, textarea, view {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  font-weight: 400;
  font-family: Red Hat Text !important;
}

.font-tomorrow {
  font-family: Tomorrow !important;
}

.font-dela-gothic-one {
  font-family: Dela Gothic One !important;
}

.text-blue-gradient {
  background: linear-gradient(#088596, #2b51d6 82.29%) text;
}

.text-blue-gradient, .text-rainbow {
  -webkit-text-fill-color: transparent;
}

.text-rainbow {
  background: linear-gradient(90deg, #29ccb9, #0091ff 49%, #ff66b7) text;
}

.bg-blue-gradient {
  background-image: linear-gradient(90deg, #3c50ffcf, #a330f485);
}

.bg-pink-gradient {
  background-image: linear-gradient(90deg, #ff51af, #cc78ff);
}

#__next {
  height: 100%;
}

.loader-dot1, .loader-dot2, .loader-dot3 {
  background-color: #fff;
  border-radius: 50%;
  width: 3px;
  height: 3px;
  margin: 2px;
}

.loader-dot1 {
  animation: 1.6s linear -.32s infinite jump;
}

.loader-dot2 {
  animation: 1.6s linear -.16s infinite jump;
}

.loader-dot3 {
  animation: 1.6s linear infinite jump;
}

@keyframes jump {
  0%, 80%, to {
    transform: scale(0);
  }

  40% {
    transform: scale(1.5);
  }
}

@keyframes ellipsis {
  0% {
    content: ".";
  }

  33% {
    content: "..";
  }

  66% {
    content: "...";
  }
}

.loading-dot:after {
  content: ".";
  text-align: left;
  width: 1em;
  animation: 1.25s infinite ellipsis;
  display: inline-block;
}

.img-shadow {
  box-shadow: 0 0 8px #00ffc2;
}

.hover\:bg-lightBlue:hover {
  background-color: #4200ff99 !important;
}

.hover\:text-opacity-60:hover {
  --tw-text-opacity: .6 !important;
}

@media (width >= 1536px) {
  .\32 xl\:mr-30 {
    margin-right: 7.5rem !important;
  }
}

body, html {
  margin: 0;
}

#sky, body, html {
  width: 100%;
  height: 100%;
}

#sky {
  background: linear-gradient(90deg, #082926, #031d33 48.96%, #321526);
  max-width: 100vw;
  top: 0;
  left: 0;
  overflow: hidden;
}

#sky, .star {
  position: absolute;
}

.star {
  opacity: 0;
  transform-origin: 0, 0;
  z-index: 1;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 0 20px #fff;
  animation-timing-function: linear, linear !important;
  animation-iteration-count: infinite, infinite !important;
  animation-delay: -30s, -30s !important;
}

.size-1 {
  width: 1px;
  height: 1px;
}

.size-2 {
  width: 2px;
  height: 2px;
}

.axis-1 {
  animation: 15.354s anim0, 15.354s fade7;
}

.axis-2 {
  animation: 38.347s anim0, 38.347s fade1;
}

.axis-3 {
  animation: 10.086s anim0, 10.086s fade9;
}

.axis-4 {
  animation: 40.082s anim4, 40.082s fade10;
}

.axis-5 {
  animation: 42.675s anim4, 42.675s fade7;
}

.axis-6 {
  animation: 27.273s anim4, 27.273s fade5;
}

.axis-7 {
  animation: 41.422s anim4, 41.422s fade10;
}

.axis-8 {
  animation: 22.688s anim8, 22.688s fade4;
}

.axis-9 {
  animation: 41.946s anim8, 41.946s fade4;
}

.axis-10 {
  animation: 20.397s anim8, 20.397s fade5;
}

.axis-11 {
  animation: 16.918s anim8, 16.918s fade10;
}

.axis-12 {
  animation: 40.849s anim12, 40.849s fade3;
}

.axis-13 {
  animation: 41.577s anim12, 41.577s fade10;
}

.axis-14 {
  animation: 18.563s anim12, 18.563s fade10;
}

.axis-15 {
  animation: 25.633s anim12, 25.633s fade2;
}

.axis-16 {
  animation: 6.804s anim16, 6.804s fade2;
}

.axis-17 {
  animation: 19.789s anim16, 19.789s fade5;
}

.axis-18 {
  animation: 21.624s anim16, 21.624s fade3;
}

.axis-19 {
  animation: 22.486s anim16, 22.486s fade3;
}

.axis-20 {
  animation: 16.841s anim20, 16.841s fade7;
}

.axis-21 {
  animation: 7.788s anim20, 7.788s fade1;
}

.axis-22 {
  animation: 17.415s anim20, 17.415s fade1;
}

.axis-23 {
  animation: 21.123s anim20, 21.123s fade4;
}

.axis-24 {
  animation: 16.872s anim24, 16.872s fade6;
}

.axis-25 {
  animation: 10.101s anim24, 10.101s fade10;
}

.axis-26 {
  animation: 21.473s anim24, 21.473s fade10;
}

.axis-27 {
  animation: 32.202s anim24, 32.202s fade10;
}

.axis-28 {
  animation: 28.175s anim28, 28.175s fade10;
}

.axis-29 {
  animation: 30.342s anim28, 30.342s fade3;
}

.axis-30 {
  animation: 34.895s anim28, 34.895s fade9;
}

.axis-31 {
  animation: 18.371s anim28, 18.371s fade1;
}

.axis-32 {
  animation: 34.589s anim32, 34.589s fade2;
}

.axis-33 {
  animation: 33.08s anim32, 33.08s fade6;
}

.axis-34 {
  animation: 43.474s anim32, 43.474s fade8;
}

.axis-35 {
  animation: 39.754s anim32, 39.754s fade8;
}

.axis-36 {
  animation: 7.089s anim36, 7.089s fade7;
}

.axis-37 {
  animation: 6.017s anim36, 6.017s fade2;
}

.axis-38 {
  animation: 29.809s anim36, 29.809s fade4;
}

.axis-39 {
  animation: 32.452s anim36, 32.452s fade4;
}

.axis-40 {
  animation: 28.65s anim40, 28.65s fade7;
}

.axis-41 {
  animation: 9.654s anim40, 9.654s fade3;
}

.axis-42 {
  animation: 6.599s anim40, 6.599s fade6;
}

.axis-43 {
  animation: 7.994s anim40, 7.994s fade10;
}

.axis-44 {
  animation: 6.109s anim44, 6.109s fade7;
}

.axis-45 {
  animation: 7.291s anim44, 7.291s fade6;
}

.axis-46 {
  animation: 12.254s anim44, 12.254s fade3;
}

.axis-47 {
  animation: 41.549s anim44, 41.549s fade6;
}

.axis-48 {
  animation: 23.834s anim48, 23.834s fade3;
}

.axis-49 {
  animation: 13.639s anim48, 13.639s fade3;
}

.axis-50 {
  animation: 34.689s anim48, 34.689s fade4;
}

.axis-51 {
  animation: 9.914s anim48, 9.914s fade1;
}

.axis-52 {
  animation: 18.706s anim52, 18.706s fade10;
}

.axis-53 {
  animation: 38.344s anim52, 38.344s fade2;
}

.axis-54 {
  animation: 14.228s anim52, 14.228s fade4;
}

.axis-55 {
  animation: 11.576s anim52, 11.576s fade6;
}

.axis-56 {
  animation: 44.34s anim56, 44.34s fade5;
}

.axis-57 {
  animation: 39.324s anim56, 39.324s fade9;
}

.axis-58 {
  animation: 34.76s anim56, 34.76s fade10;
}

.axis-59 {
  animation: 25.818s anim56, 25.818s fade1;
}

.axis-60 {
  animation: 43.529s anim60, 43.529s fade8;
}

.axis-61 {
  animation: 10.55s anim60, 10.55s fade4;
}

.axis-62 {
  animation: 29.69s anim60, 29.69s fade6;
}

.axis-63 {
  animation: 24.742s anim60, 24.742s fade8;
}

.axis-64 {
  animation: 38.009s anim64, 38.009s fade3;
}

.axis-65 {
  animation: 10.929s anim64, 10.929s fade4;
}

.axis-66 {
  animation: 37.98s anim64, 37.98s fade5;
}

.axis-67 {
  animation: 19.86s anim64, 19.86s fade8;
}

.axis-68 {
  animation: 41.138s anim68, 41.138s fade3;
}

.axis-69 {
  animation: 11.08s anim68, 11.08s fade9;
}

.axis-70 {
  animation: 16.406s anim68, 16.406s fade4;
}

.axis-71 {
  animation: 23.46s anim68, 23.46s fade7;
}

.axis-72 {
  animation: 13.389s anim72, 13.389s fade7;
}

.axis-73 {
  animation: 24.974s anim72, 24.974s fade7;
}

.axis-74 {
  animation: 31.559s anim72, 31.559s fade6;
}

.axis-75 {
  animation: 40.315s anim72, 40.315s fade6;
}

.axis-76 {
  animation: 42.1s anim76, 42.1s fade6;
}

.axis-77 {
  animation: 36.19s anim76, 36.19s fade6;
}

.axis-78 {
  animation: 7.916s anim76, 7.916s fade4;
}

.axis-79 {
  animation: 20.561s anim76, 20.561s fade6;
}

.axis-80 {
  animation: 29.642s anim80, 29.642s fade9;
}

.axis-81 {
  animation: 40.733s anim80, 40.733s fade9;
}

.axis-82 {
  animation: 27.751s anim80, 27.751s fade6;
}

.axis-83 {
  animation: 20.734s anim80, 20.734s fade10;
}

.axis-84 {
  animation: 16.01s anim84, 16.01s fade10;
}

.axis-85 {
  animation: 31.924s anim84, 31.924s fade2;
}

.axis-86 {
  animation: 13.118s anim84, 13.118s fade4;
}

.axis-87 {
  animation: 13.12s anim84, 13.12s fade7;
}

.axis-88 {
  animation: 26.122s anim88, 26.122s fade7;
}

.axis-89 {
  animation: 32.267s anim88, 32.267s fade1;
}

.axis-90 {
  animation: 43.26s anim88, 43.26s fade4;
}

.axis-91 {
  animation: 29.614s anim88, 29.614s fade7;
}

.axis-92 {
  animation: 10.89s anim92, 10.89s fade8;
}

.axis-93 {
  animation: 24.756s anim92, 24.756s fade5;
}

.axis-94 {
  animation: 41.678s anim92, 41.678s fade9;
}

.axis-95 {
  animation: 24.091s anim92, 24.091s fade5;
}

.axis-96 {
  animation: 44.393s anim96, 44.393s fade10;
}

.axis-97 {
  animation: 6.387s anim96, 6.387s fade2;
}

.axis-98 {
  animation: 5.193s anim96, 5.193s fade6;
}

.axis-99 {
  animation: 5.017s anim96, 5.017s fade1;
}

@keyframes fade1 {
  6% {
    opacity: 0;
  }

  16% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade2 {
  18% {
    opacity: 0;
  }

  28% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade3 {
  16% {
    opacity: 0;
  }

  26% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade4 {
  7% {
    opacity: 0;
  }

  17% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade5 {
  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade6 {
  18% {
    opacity: 0;
  }

  28% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade7 {
  7% {
    opacity: 0;
  }

  17% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade8 {
  7% {
    opacity: 0;
  }

  17% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade9 {
  1% {
    opacity: 0;
  }

  11% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade10 {
  19% {
    opacity: 0;
  }

  29% {
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@keyframes anim4 {
  to {
    transform: translate(77.4867vw, 19.8952vh);
  }
}

@keyframes anim8 {
  to {
    transform: translate(70.1045vw, 38.5403vh);
  }
}

@keyframes anim12 {
  to {
    transform: translate(58.3175vw, 54.7638vh);
  }
}

@keyframes anim16 {
  to {
    transform: translate(42.8661vw, 67.5462vh);
  }
}

@keyframes anim20 {
  to {
    transform: translate(24.7214vw, 76.0845vh);
  }
}

@keyframes anim24 {
  to {
    transform: translate(5.02324vw, 79.8421vh);
  }
}

@keyframes anim28 {
  to {
    transform: translate(-14.9905vw, 78.583vh);
  }
}

@keyframes anim32 {
  to {
    transform: translate(-34.0623vw, 72.3862vh);
  }
}

@keyframes anim36 {
  to {
    transform: translate(-50.9939vw, 61.6411vh);
  }
}

@keyframes anim40 {
  to {
    transform: translate(-64.7214vw, 47.0228vh);
  }
}

@keyframes anim44 {
  to {
    transform: translate(-74.3821vw, 29.45vh);
  }
}

@keyframes anim48 {
  to {
    transform: translate(-79.3692vw, 10.0267vh);
  }
}

@keyframes anim52 {
  to {
    transform: translate(-79.3692vw, -10.0267vh);
  }
}

@keyframes anim56 {
  to {
    transform: translate(-74.3821vw, -29.45vh);
  }
}

@keyframes anim60 {
  to {
    transform: translate(-64.7214vw, -47.0228vh);
  }
}

@keyframes anim64 {
  to {
    transform: translate(-50.9939vw, -61.6411vh);
  }
}

@keyframes anim68 {
  to {
    transform: translate(-34.0623vw, -72.3862vh);
  }
}

@keyframes anim72 {
  to {
    transform: translate(-14.9905vw, -78.583vh);
  }
}

@keyframes anim76 {
  to {
    transform: translate(5.0233vw, -79.8421vh);
  }
}

@keyframes anim80 {
  to {
    transform: translate(24.7215vw, -76.0845vh);
  }
}

@keyframes anim84 {
  to {
    transform: translate(42.8667vw, -67.5461vh);
  }
}

@keyframes anim88 {
  to {
    transform: translate(58.319vw, -54.7634vh);
  }
}

@keyframes anim92 {
  to {
    transform: translate(70.1084vw, -38.5393vh);
  }
}

.combine-modal .ant-modal-content {
  box-shadow: none;
  text-align: center;
  padding: 0;
  background-color: #0000 !important;
}

.airdrop-modal .ant-modal-content {
  background-color: #032842ad;
  border: 2px solid #083a5d;
  border-radius: 24px;
  padding: 24px 48px;
}

.ipblock-modal .ant-modal-content {
  text-align: center;
  background-color: #08242fad;
  border: 2px solid #113e4f;
  border-radius: 24px;
  width: max-content;
  padding: 48px;
}

.treasure-card .ant-input {
  text-align: center;
  color: #fff;
  background: none;
  border: none;
}

.treasure-card .ant-input:focus, .treasure-card .ant-input:hover {
  box-shadow: none;
  border: none;
}

.ant-notification .ant-notification-notice-wrapper .ant-notification-notice-close {
  top: 5px !important;
  right: 5px !important;
}

.mantaAirdrop .ant-image {
  width: 100% !important;
}

@font-face {
  font-display: swap;
  font-family: Dela Gothic One;
  font-style: normal;
  font-weight: 400;
  src: url("dela-gothic-one-v15-latin-regular.4ec156cb.57177120.woff2") format("woff2");
}

@font-face {
  font-family: Tomorrow;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/tomorrow/v17/WBLhrETNbFtZCeGqgR1pXnXPDMWDikd5.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Red Hat Text;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/redhattext/v14/RrQXbohi_ic6B3yVSzGBrMxQaKctMc-JPQ.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Red Hat Text;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/redhattext/v14/RrQXbohi_ic6B3yVSzGBrMxQaKctMc-JPQ.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/*# sourceMappingURL=index.23d32a2f.css.map */
